<template>
  <div class="flex gap-8">
    <tml-icon-button
      v-for="icon in icons"
      :key="icon.text"
      class="text-sm pointer-events-none"
      :icon="icon.icon"
      :text="icon.text"
      icon-color="var(--tml-text-color)"
      :background-color="backgroundColor"
      max-size="48px"
      circle
    />
  </div>
</template>

<script>
import {addAlpha} from '@teemill/utilities';
import {formatUrl} from '@teemill/common/helpers';

import {
  faSeedling,
  faSync,
  faHandHoldingHeart,
  faWindTurbine,
} from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'EcoIcons',

  inject: ['theme'],

  props: {
    product: Object,
  },

  data() {
    return {
      iconMap: {
        'organic-icon.png': {
          text: 'Organic',
          icon: faSeedling,
        },
        'circular-icon.png': {
          text: 'Circular',
          icon: faSync,
        },
        'low-carbon-icon.png': {
          text: 'Renewable',
          icon: faWindTurbine,
        },
        'ethical-icon.png': {
          text: 'Circular',
          icon: faHandHoldingHeart,
        },
      },
    };
  },

  computed: {
    icons() {
      return this.product.ecoIcons
        .map(icon => this.iconMap[icon])
        .filter(i => i);
    },

    backgroundColor() {
      return addAlpha(this.theme().get('text.color'), 0.05);
    },
  },

  methods: {
    formatUrl,
  },
};
</script>
